<!-- 编辑弹窗 -->
<template>
  <div>
    <a-modal
      :width="580"
      :visible="visible"
      :confirm-loading="loading"
      :title="isUpdate ? '修改' : '新建'"
      :body-style="{ paddingBottom: '8px' }"
      @update:visible="updateVisible"
      @ok="save"
    >
      <a-form
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }"
      >
        <a-row :gutter="16">
          <a-col :sm="24" :xs="24">
            <a-form-item label="分类名称:" name="categoryName">
              <a-input
                v-model:value="form.categoryName"
                placeholder="请输入分类名称"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24">
            <a-form-item label="分类备注:" name="comments">
              <a-input
                v-model:value="form.comments"
                placeholder="请输入分类备注"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24">
            <a-form-item label="是否显示:" name="isShow">
              <a-select
                v-model:value="form.isShow"
                placeholder="请选择是否显示"
                allow-clear
              >
                <a-select-option
                  v-for="item in isShowList"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="排序:"
              name="sortNumber"
            >
              <a-input-number
                v-model:value="form.sortNumber"
                :min="0"
                :step="1"
              />
              <span class="span" style="margin-left:5px;color:#868686">数字越小越靠前</span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import * as activityCategoryApi from '@/api/activity/activityCategory.js'
import * as dictDataApi from '@/api/dict/dictData.js'
export default {
  name: 'formInfoEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {},
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 编辑弹窗表单验证规则
      rules: {
        categoryName: [
          {
            required: true,
            message: '请输入分类名称',
            type: 'string',
            validateTrigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      isShowList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
    }
  },
  mounted() {
    this.queryDataStates()
  },
  methods: {
    resetForm() {
      this.form = {}
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          activityCategoryApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.resetForm()
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // /* 查询是否显示类型 */
    queryDataStates() {
      dictDataApi
        .getDictData({ dictCode: 'isShow' })
        .then((res) => {
          if (res.code === 0) {
            this.isShowList = res.data
            this.isShowList.map((item) => {
              item.dictDataCode = parseInt(item.dictDataCode)
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
